<template>
  <div :class="textClass">
    <DgText :data="text" />
    <DgIcon
      v-if="text && !withoutTitle"
      :ref="`chevicon-${chevronIcon}`"
      class="dg-dashboard-widget__collapse-icon"
      :style="{
        '--widget-text-collapse-icon-color': portalSettings.portalColor,
      }"
      :name="chevronIcon"
      :aria-pressed="expand"
      @click="expand"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import MixinFixWidget from "@/mixins/fixWidget";
import { DgIcon } from "@engenharia/dashgoo-ui";
import { DgText } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetText",
  mixins: [MixinFixWidget],
  components: {
    DgText,
    DgIcon,
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    ...mapState({
      portalSettings: (state) => state.portal?.settings,
    }),
    portalColor() {
      const portalColor = this.portalSettings?.portalColor;
      return portalColor;
    },
    text() {
      const isText = typeof this.widget?.data === "string";
      return isText ? this.widget?.data : "";
    },
    withoutTitle() {
      return !this.widget?.title;
    },
    textClass() {
      return {
        "dg-dashboard-widget__collapse-content": true,
        "dg-dashboard-widget__collapse-content--open":
          this.expanded || !!this.withoutTitle,
        "dg-dashboard-widget__collapse-content--specialPadding":
          !!this.withoutTitle,
      };
    },
    chevronIcon() {
      return this.expanded ? "ChevronUp" : "ChevronDown";
    },
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
      this.observeWidgetHeight();
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-dashboard-widget__collapse-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 200ms;
  height: auto;

  &--open {
    grid-template-rows: 1fr;

    &.dg-dashboard-widget__collapse-content--specialPadding {
      padding-left: 1em;
    }
  }

  & .dg-dashboard-widget__collapse-icon {
    --widget-text-collapse-icon-background-color: #f2f2f2;
    position: absolute;
    right: 1rem;
    top: 15px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: var(--widget-text-collapse-icon-background-color);
    border-radius: 500px;
    color: var(--widget-text-collapse-icon-color);
  }

  & > .dg-text {
    color: #616161;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    overflow: hidden;
  }
}
</style>
